html,
body {
  margin: 0;
  font-family: 'Arial', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: '62.5%';
  height: 100vh;
  width: 100vw;
  min-height: 100vh;
  min-width: 100vw;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

/* nunito-sans-regular - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/nunito-sans-v6-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/nunito-sans-v6-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/nunito-sans-v6-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('./fonts/nunito-sans-v6-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('./fonts/nunito-sans-v6-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./fonts/nunito-sans-v6-latin-regular.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-700 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/nunito-sans-v6-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/nunito-sans-v6-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/nunito-sans-v6-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/nunito-sans-v6-latin-700.woff')
      format('woff'),
    /* Modern Browsers */ url('./fonts/nunito-sans-v6-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./fonts/nunito-sans-v6-latin-700.svg#NunitoSans') format('svg'); /* Legacy iOS */
}

span.nobr {
  white-space: nowrap;
}
